import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Banner from '../components/Banner';

import firstTile from '../assets/images/firstTile.jpeg';
import secondTile from '../assets/images/secondTile.jpeg';
import thirdTile from '../assets/images/thirdTile.jpeg';

import picTile2 from '../assets/images/picTile2.jpg';
import picTile3 from '../assets/images/picTile3.jpg';
import picTile9 from '../assets/images/picTile9.jpeg';

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Karmen Towne Training"
          meta={[
            { name: 'description', content: 'Karmen Town Training' },
            {
              name: 'keywords',
              content:
                'Personal Training,Fitness Coach,Personal Trainer,Training,Coaching,Personal Fitness,Coach,Nutrition,Weight Training,Gym,Online Training,In-Home Training,Virtual Training,Personal Training Programs,Online Training,In-Home (Personal) Training,Virtual (Personal) Training,Personal Training Programs',
            },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${firstTile})` }}>
              <header className="major">
                <h3>1 on 1 Personal Training</h3>
              </header>
              <Link to="/plans#personal" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${secondTile})` }}>
              <header className="major">
                <h3>Virtual Training/Customized Plans</h3>
              </header>
              <Link to="/plans#virtual" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${thirdTile})` }}>
              <header className="major">
                <h3>Partner/Small Group Training</h3>
              </header>
              <Link to="/plans#group" className="link primary"></Link>
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Who Am I?</h2>
              </header>
              <p>
                Hello there! My name is Karmen, and I am going to be the best
                thing that has happened to you, emotionally and physically. I
                have been a personal trainer for over 10 years, and have had the
                privilege to work with some amazing people in that time.
                Coaching others has always been my passion, and I am grateful
                that what I get to do for a living can positively impact people
                on a daily basis. Will you be next?
              </p>
              <section id="three" className="picture-tiles">
                <article
                  style={{ backgroundImage: `url(${picTile2})` }}
                ></article>
                <article
                  style={{ backgroundImage: `url(${picTile3})` }}
                ></article>
                <article
                  style={{ backgroundImage: `url(${picTile9})` }}
                ></article>
              </section>

              <ul className="actions get-started">
                <li>
                  <Link to="/plans" className="button">
                    Get Started
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
