import React from 'react';

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major get-started">
        <h1>Are You Ready to Change Your Life?</h1>
      </header>
      <div className="content get-started">
        <p>
          Welcome to Karmen Towne Training! You can be more than what you are.
          Let’s start building that confidence!
        </p>
      </div>
      <ul className="actions get-started">
        <li>
          <a href="/plans" className="button">
            Get Started
          </a>
        </li>
      </ul>
    </div>
  </section>
);

export default Banner;
